<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import VTabBar from "@magnit/core/src/components/VTabBar/VTabBar.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import ShopIcon from "@magnit/icons/src/assets/svg/icons/base/24-outline-shop.svg";
import CartIcon from "@magnit/icons/src/assets/svg/icons/base/24-outline-cart.svg";
import CatalogIcon from "@magnit/icons/src/assets/svg/icons/base/24-outline-menu_square.svg";
import type {
  ITabBarItem,
  ITabBarPayload,
} from "@magnit/core/src/components/VTabBar/VTabBar.types";
import { EcomRoutes } from "~/layers/ecom/utils/routes";

enum NavName {
  Main = "Main",
  Basket = "Basket",
  Catalog = "Catalog",
}

// const analyticEventsMap: Record<string, string> = {
//   Main: "Main",
//   Basket: "Basket",
//   Profile: "Catalog",
// };

const navItems: ITabBarItem[] = [
  {
    name: NavName.Main,
    icon: ShopIcon,
    text: "Главная",
    link: Routes.Main,
  },
  {
    name: NavName.Basket,
    icon: CartIcon,
    text: "Корзина",
    link: EcomRoutes.Basket,
  },
  {
    name: NavName.Catalog,
    icon: CatalogIcon,
    text: "Каталог",
    link: Routes.Catalog,
  },
];

const router = useRouter();
const route = useRoute();
const { send } = useAnalytics();

const active = computed<NavName | null>(() => {
  if (route.path.startsWith(Routes.Catalog)) {
    return NavName.Catalog;
  }
  if (route.path === EcomRoutes.Basket) {
    return NavName.Basket;
  }
  if (route.path === Routes.Main) {
    return NavName.Main;
  }
  return null;
});

const onClick = (payload: ITabBarPayload) => {
  // send(`Navbar:${analyticEventsMap[payload.name]}:Click`);
  router.push(payload.link);
};

onMounted(() => {
  send("Navbar:View");
});
</script>

<template>
  <VHidden :hide="{ xs: false, ml: true }">
    <VTabBar :items="navItems" :active="active" class="nav-bar" @on-click="onClick($event)" />
  </VHidden>
</template>
