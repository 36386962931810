<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import {
  CLIENT_LINKS,
  COMPANY_LINKS,
  MANAGEMENT_LINKS,
} from "@magnit/unit-navigation/src/VNavigation.vars";
import VHeader from "@magnit/unit-header/src/VHeader.vue";
import { provideKeys } from "@magnit/unit-catalog/src/unit-catalog.vars";
import type { ISubMenuLinks } from "@magnit/unit-navigation/src/VNavigation.types";
import { addUtmTags } from "@magnit/unit-navigation/src/VNavigation.utils";
import { LocalProvidersKeys } from "~/enums/localProvidersKeys";
import { Routes } from "~/utils/routes";
import type { ICategory } from "~/typings/categories";

const config = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
const categoriesStore = useCategoriesStore();
const { authorization } = usePublicConfig();
const { isAuthorized } = useAuth();
const userStore = useUserStore();
const subscriptionStore = useSubscriptionStore();

const isAdultApproved = inject<Ref<boolean>>(provideKeys.approved, ref(false));
const isAgeConfirmationActive = inject<Ref<boolean>>(
  LocalProvidersKeys.ageConfirmationActive,
  ref(false),
);

const openMenu = ref(false);
const openSearchSuggest = ref(false);
const searchValue = ref((route.query.term as string) || "");

const companylinks = cloneDeep(COMPANY_LINKS);
companylinks.links.splice(2, 0, {
  label: "Условия использования файлов cookies",
  url: Routes.CookiePolicy,
  onClick: (e: Event) => {
    e.preventDefault();
    router.push(Routes.CookiePolicy);
  },
});

const submenuLinks: ISubMenuLinks = addUtmTags(
  [CLIENT_LINKS, MANAGEMENT_LINKS, companylinks],
  config.public.siteUrl,
  "layout",
  "header",
);

const authEnabled = computed(() => String(authorization.value) === "1");

const onClickAuth = () => {
  if (authEnabled.value) {
    if (isAuthorized.value) {
      router.push(Routes.Profile);
    } else publish(PUBSUB_AUTHORIZE);
  } else {
    document.location.href =
      "https://my.magnit.ru/?utm_source=magnit.ru&utm_campaign=header&utm_medium=free";
  }
};
const onClickItem = (category: ICategory) => {
  router.push(category.url);
  openMenu.value = false;
};

const onSearchSubmit = (searchValue: string) => {
  router.push(encodeURI(`${Routes.Search}?term=${searchValue}`));
};

const onSearchClear = () => {};

const checkSearchValue = () => {};

watch(
  () => openSearchSuggest.value,
  (next) => {
    if (!next) {
      if (isAgeConfirmationActive.value) {
        openSearchSuggest.value = true;
      }
    }
  },
);
watch(
  () => isAdultApproved.value,
  (next) => {
    if (next) {
      nextTick(() => (openSearchSuggest.value = false));
    }
  },
);

const bottomMenuLinks = APP_HEADER_BOTTOM_MENU;
</script>

<template>
  <VHeader
    v-model:open-catalog="openMenu"
    v-model:open-search-suggest="openSearchSuggest"
    v-model:search-value="searchValue"
    class="js-header header-magnit"
    :authorized="Boolean(userStore.profile?.userId)"
    :username="userStore.profile?.firstName"
    :premium="subscriptionStore.isSubscribed"
    :submenu-links="submenuLinks"
    :bottom-menu-links="bottomMenuLinks"
    @auth:click="onClickAuth"
    @search:submit="onSearchSubmit"
    @search:focus="checkSearchValue"
    @search:clear="onSearchClear"
  >
    <template #top>
      <ClientOnly>
        <AppInstallBanner />
      </ClientOnly>
    </template>
    <template #catalog>
      <AppCatalogNavigation
        :items="categoriesStore.structure"
        :open="openMenu"
        root
        @item:click="onClickItem"
      />
    </template>
    <template #location>
      <ClientOnly>
        <AppLocation />
      </ClientOnly>
    </template>
    <template #search>
      <AppSearchSuggest
        v-model:open-suggest="openSearchSuggest"
        v-model:search-value="searchValue"
      />
    </template>
  </VHeader>
</template>

<style lang="postcss" scoped>
:global(.pl-header .pl-header-catalog-menu__content) {
  --pl-header-catalog-menu-content: 0;

  width: 100%;

  @media (--pl-viewport-from-ml) {
    --pl-header-catalog-menu-content: calc(var(--pl-wrapper-indent) - 16px);

    width: initial;
  }
}

/* некоторые стили стоят чтобы не разламывало шапку, пока полные стили из дизайн системы подгружаются */

/* TODO: по-хорошему надо проресёрчить порядок загрузки и почему эти стили включает в очередь загрузки позднее чем хотелось-бы? */
:global(.pl-header .pl-navigation__container) {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:global(.pl-header .pl-navigation__links) {
  display: flex;
  align-items: center;
}

:global(.pl-header .pl-navigation-link__container) {
  display: flex;
  align-items: center;
}

:global(.header-magnit.pl-header) {
  background-color: var(--pl-global-background-primary);
}
</style>
