export const loadScript = (src: string, async = true): Promise<void> => {
  return new Promise((resolve, reject) => {
    const element = document.createElement("script");

    element.setAttribute("src", src);
    element.setAttribute("type", "text/javascript");
    if (async) {
      element.setAttribute("async", "true");
    }

    element.onload = () => {
      resolve();
    };

    element.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(element);
  });
};
