<script setup>
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VYaSlotErrorPopup from "@magnit/core/src/containers/VYaSlot/VYaSlotErrorPopup.vue";
import VSkeletonProductDetails from "@magnit/unit-product-details/src/components/skeleton/VSkeletonProductDetails.vue";
import VSkeletonProductDetailsPromo from "@magnit/unit-product-details/src/components/skeleton/VSkeletonProductDetailsPromo.vue";
import serviceWorkerInit from "@magnit/layer-profile/sw";
import { useSlotsStore } from "~/stores/useSlotsStore";
import { RouteNames, Routes } from "~/utils/routes";

const { isAuthorized } = useAuth();
const slotsStore = useSlotsStore();
const storesStore = useStoresStore();
const modalsStore = useModalsStore();
const categoriesStore = useCategoriesStore();
const pushStore = usePushStore();
const { refreshKey } = useRefresh();
const { diffShopCode, isDiffShopCodeQuery, requestShopCode } = storeToRefs(storesStore);
const route = useRoute();
const { kfpCid, isEcom } = usePublicConfig();

useFirebaseInit();

const cookiePopupOpen = ref(false);
const showYaSlotPopup = ref(false);
const isLandingPage = computed(() => route.path.includes(Routes.Mplus));
const isProfilePage = computed(() => route.path.startsWith(Routes.Profile));
const isNeedSkeleton = computed(() =>
  [
    RouteNames.Catalog,
    RouteNames.Popular,
    RouteNames.ProductGoods,
    RouteNames.PromoCatalog,
    RouteNames.PromoCatalogCategory,
    RouteNames.ProductPromo,
    RouteNames.CatalogCategorySlug,
  ].includes(route.name),
);

const isPopularPage = computed(() => RouteNames.Popular === route.name);
const isProductPage = computed(() => RouteNames.ProductGoods === route.name);
const isPromoProductPage = computed(() => RouteNames.ProductPromo === route.name);

await Promise.all(
  [
    storesStore.initializeSelectedStore(),
    !diffShopCode.value && categoriesStore.requestCategories(),
  ].filter(Boolean),
);
await categoriesStore.requestCategories();

watchEffect(() => {
  showYaSlotPopup.value =
    !slotsStore.slotPopupWasDisplayed &&
    !modalsStore.getActive() &&
    !cookiePopupOpen.value &&
    slotsStore.slotStatus === IYaSlotApiStatus.ERROR;
  if (showYaSlotPopup.value) {
    slotsStore.slotPopupWasDisplayed = true;
  }
});

watch(requestShopCode, () => categoriesStore.requestCategories(true));

onBeforeMount(async () => {
  if (navigator?.serviceWorker) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      await serviceWorkerInit();
    }
  }
});

onMounted(async () => {
  if (kfpCid.value) {
    loadScript(`/np/scripts/${kfpCid.value}.das.obf.js`);
  }
  nextTick(async () => {
    if (!("Notification" in window)) {
      return;
    }
    if (isAuthorized.value && Notification?.permission === "granted") {
      await pushStore.updateToken();
    }
  });
});
</script>

<template>
  <div class="layout-default" :class="{ 'layout-default__with-navbar': isEcom }">
    <LayoutDefaultHeader />
    <main class="layout-default__main" :class="{ landing: isLandingPage, profile: isProfilePage }">
      <div v-show="!isDiffShopCodeQuery" class="layout-default__main__container">
        <slot :key="refreshKey" />
      </div>
      <template v-if="isDiffShopCodeQuery && isNeedSkeleton">
        <SkeletonPopular v-if="isPopularPage" />
        <VSkeletonProductDetails v-else-if="isProductPage" />
        <VSkeletonProductDetailsPromo v-else-if="isPromoProductPage" />
        <SkeletonCatalog v-else />
      </template>
    </main>
    <LayoutDefaultFooter />
    <VCookiePolicyPopup @visible="(visible) => (cookiePopupOpen = visible)" />
    <AppToastCollection />
    <VYaSlotErrorPopup :show="showYaSlotPopup" />
    <VTopButton :class="{ landing: isLandingPage }" />
    <AppNavBar v-if="isEcom" />
  </div>
</template>

<style scoped lang="postcss">
.layout-default {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--pl-unit-x2) 0 64px;
    min-height: 600px;

    &__container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__main.profile {
    padding: unset;
    min-height: unset;
  }

  &__main.landing {
    padding-bottom: 52px;

    @media (--pl-viewport-from-ml) {
      padding-bottom: 64px;
    }

    @media (--pl-viewport-from-xl) {
      padding-bottom: 52px;
    }
  }

  &__with-navbar {
    padding-bottom: calc(var(--navbar-height) - 1px);

    @media (--pl-viewport-from-ml) {
      padding-bottom: 0;
    }

    :deep(.top-button) {
      bottom: calc(var(--navbar-height) - 1px + var(--pl-unit-x6));

      @media (--pl-viewport-from-ml) {
        bottom: var(--pl-unit-x6);
      }
    }
  }

  :deep(.top-button.landing) {
    display: none;

    @media (--pl-viewport-from-ml) {
      display: block;
    }
  }
}
</style>
