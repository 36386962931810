<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VTooltip from "@magnit/core/src/components/VTooltip/VTooltip.vue";
import VLoader from "@magnit/core/src/components/VLoader/VLoader.vue";
import StoreSelectPrompt from "@magnit/unit-shop-select/src/components/ShopSelectPrompt.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import MagnitIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-main-magnit-colored-background.svg";
import MasterIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-master-colored-background.svg";
import CosmeticIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-cosmetic-colored-background.svg";
import ArrowIcon from "@magnit/icons/src/assets/svg/icons/base/24-outline-right-corner.svg";
import { ShopType } from "~/typings/api/goods";
import { collectHeaderCityPayload } from "~/utils/analytics";

const storesStore = useStoresStore();
const { initialized, currentStoreAddress, currentStoreType, isDiffShopCodeQuery, storePrompted } =
  storeToRefs(storesStore);
const shopSelectOpen = ref(false);
const { send } = useAnalytics();

const showStorePrompt = computed(
  () => !storePrompted.value && !isDiffShopCodeQuery.value && !shopSelectOpen.value,
);

const getIconType = (storeType: string) => {
  switch (storeType) {
    case ShopType.AtHouse:
      return MagnitIcon;
    case ShopType.Cosmetic:
      return CosmeticIcon;
    case ShopType.Master:
      return MasterIcon;
    default:
      return MagnitIcon;
  }
};

const iconComponent = computed(() => {
  return getIconType(currentStoreType.value);
});
const handleFigureClick = () => {
  send("Header:CityStore:Click", collectHeaderCityPayload());
  if (shopSelectOpen.value === true) return;
  shopSelectOpen.value = true;
  scrollLock.enable();
};
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (!isIntersecting) return;
  send("Header:CityStore:View", collectHeaderCityPayload());
};
const handleShopSelectClose = () => {
  scrollLock.disable();
  shopSelectOpen.value = false;
};

const onStorePromptApprove = () => {
  storesStore.setStorePrompt();
};
const onStorePromptReject = () => {
  handleFigureClick();
};
</script>

<template>
  <section class="app-location">
    <div
      role="button"
      class="app-location__inner"
      data-test-id="map-button"
      @click="handleFigureClick"
    >
      <div v-if="!initialized" class="app-location__inner--loading">
        <VLoader size="s" />
      </div>
      <template v-else>
        <component :is="iconComponent" class="app-location__icon" />
        <div
          v-intersection-observer="onVisibility"
          class="app-location__info"
          :title="currentStoreAddress"
        >
          <VText
            class="app-location__info--address"
            :font="{ xs: 'body-small-accent-low', m: 'caption-large-accent-low' }"
          >
            {{ currentStoreAddress }}
          </VText>
        </div>
        <VHidden :hide="{ s: false, m: true }">
          <VIcon class="app-location__arrow" color="secondary">
            <ArrowIcon />
          </VIcon>
        </VHidden>
      </template>
    </div>
    <Transition name="scale">
      <AppShopSelect v-if="shopSelectOpen" @close:app-shop-select="handleShopSelectClose" />
    </Transition>
    <VHidden class="app-location-store-wrapper" :hide="{ xs: true, m: false }">
      <VTooltip
        v-if="isDiffShopCodeQuery"
        show-on-create
        interactive
        :offset="[0, -8]"
        :max-width="431"
        :show-arrow="false"
        :hide-on-click="false"
        :closable="false"
        trigger="manual"
        placement="bottom-end"
      >
        <template #content>
          <AppShopLocation />
        </template>
      </VTooltip>
    </VHidden>
    <VModal
      id="store"
      :closable="false"
      wrapper-class="store-bottom-sheet"
      active
      :open="isDiffShopCodeQuery"
      type="bottom-sheet"
    >
      <AppShopLocation />
    </VModal>
    <StoreSelectPrompt
      :address="currentStoreAddress"
      :show="showStorePrompt"
      @approve="onStorePromptApprove"
      @reject="onStorePromptReject"
    />
  </section>
</template>

<style lang="postcss" scoped>
.app-location {
  display: flex;
  flex-direction: column;
  height: 48px;

  &__inner {
    display: flex;
    gap: var(--pl-unit-x2);
    padding: var(--pl-unit-x2) 0;
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative;

    &--loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    @media (--pl-viewport-from-m) {
      width: 220px;
      border: 1px solid var(--pl-border-secondary-disabled);
      border-radius: var(--pl-button-radius-l);
      padding: 7px var(--pl-unit-x2);
      background-color: var(--pl-button-background-tertiary-default);

      &:hover {
        border-color: var(--pl-button-border-hover);
        background-color: var(--pl-button-background-tertiary-hover);
      }

      &:active {
        border-color: var(--pl-button-border-pressed);
        background-color: var(--pl-button-background-tertiary-pressed);
      }
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__arrow {
    display: flex;
  }

  &__info {
    flex: 1;
    overflow: hidden;

    &--address {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  :deep(.tippy-content) {
    padding: var(--pl-unit-x4);
  }
}

.app-location-store-wrapper {
  height: 0;
  width: 0;
  align-self: flex-end;
}

:global(.store-bottom-sheet .pl-modal-content) {
  padding: var(--pl-unit-x8) var(--pl-unit-x5);
}

:global(.store-bottom-sheet .app-location-store__inner--actions) {
  flex-direction: column;
}

:global(.store-bottom-sheet) {
  @media (--pl-viewport-from-m) {
    display: none !important;
  }
}
</style>
