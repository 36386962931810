<script setup lang="ts">
import type {
  IToast,
  IToastPseudo,
  IToastPayload,
  IToastDisplay,
} from "~/typings/components/appToast";

const props = defineProps({
  lifespan: {
    type: Number,
    default: 3000,
  },
  transitionName: {
    type: String,
    default: "slide-down",
  },
});

const toastCollection = ref<Array<IToast | IToastPseudo>>([]);

const displayList = computed(() => {
  if (!toastCollection.value.length) return [];
  const lastIndex = toastCollection.value.length - 1;
  return toastCollection.value.map((toast, index) => ({
    ...toast,
    index,
    key: toast.id,
    active: index === lastIndex && toast.pseudo === false,
  })) as IToastDisplay[];
});

usePubSub(PUBSUB_ADD_TOAST, (payload: Partial<IToastPayload>) => {
  if (!payload.title && !payload.text) {
    return logError("AppToastCollection: Empty toast", { payload });
  }
  const entry = {
    id: `toast-${generateUUID()}`,
    lifespan: props.lifespan,
    icon: ICON_TOAST_WARNING,
    pseudo: false,
    ...payload,
  } as IToast;
  if (toastCollection.value.length < 2) {
    toastCollection.value.push(entry);
  } else {
    toastCollection.value = [toastCollection.value[toastCollection.value.length - 1], entry];
  }
});

const handleCloseToast = (toast: IToastDisplay) => {
  const lastIndex = toastCollection.value.length - 1;
  if (toast.index === lastIndex) {
    toastCollection.value.push({ id: `pseudo-toast-${generateUUID()}`, pseudo: true });
  } else {
    toastCollection.value = toastCollection.value.filter((s) => s.id !== toast.id);
  }
};
</script>

<template>
  <section class="toast-collection">
    <AppToast
      v-for="single in displayList"
      :key="single.id"
      :active="single.active"
      :title="single.title"
      :text="single.text"
      :icon="single.icon"
      :lifespan="lifespan"
      :pseudo="single.pseudo"
      @close:toast="handleCloseToast(single)"
    />
  </section>
</template>

<style lang="postcss" scoped>
.toast-collection {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20px;
  align-items: center;
  z-index: var(--pl-z-index-toast);
  height: 0;
  overflow: visible;
}
</style>
