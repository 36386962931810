<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import MagnitIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-main-magnit-colored-background.svg";
import MasterIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-master-colored-background.svg";
import CosmeticIcon from "@magnit/icons/src/assets/svg/icons/base/40-logo-cosmetic-colored-background.svg";
import { SHOPS_NAMES_MAP } from "@magnit/unit-shop-select/src/constants";
import { ShopSelectShopType } from "@magnit/unit-shop-select/src/enums";
import { ShopType } from "~/typings/api/goods";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";

const router = useRouter();
const storesStore = useStoresStore();
const { previouslySelectedStore, diffStore, currentStoreAddress } = storeToRefs(storesStore);

const diffStoreAddress = computed(() => (diffStore.value ? diffStore.value.address : ""));
const diffStoreType = computed(() =>
  diffStore.value ? diffStore.value.storeType : ShopSelectShopType.AtHouse,
);

const getIconType = (storeType: string) => {
  switch (storeType) {
    case ShopType.AtHouse:
      return MagnitIcon;
    case ShopType.Cosmetic:
      return CosmeticIcon;
    case ShopType.Master:
      return MasterIcon;
    default:
      return MagnitIcon;
  }
};

const tooltipIconComponent = computed(() => {
  return getIconType(diffStore.value?.storeType ?? "");
});

const storeTypeLabel = computed(() => SHOPS_NAMES_MAP[diffStoreType.value as ShopSelectShopType]);

const setSelectedStore = (store: IStoreSearchStore, displayNotification = false) => {
  try {
    storesStore.submitSelectedStore(store);
  } catch (err) {
    logError("Ошибка при выставлении магазина в ShopLocation ", { err });
    storesStore.setDefaultStore();
  } finally {
    if (displayNotification) {
      successNotification(
        `Теперь показываем каталог магазина по адресу ${currentStoreAddress.value}`,
      );
    }
  }
};
const handleChangeStore = () => setSelectedStore(diffStore.value!, true);
const handleCloseStoreChange = async () => {
  await router.push({ path: "/" });
  setSelectedStore(previouslySelectedStore.value!);
};
</script>

<template>
  <div class="app-location-store">
    <div class="app-location-store__text">
      <VText tag="div" font="title-large">
        Чтобы посмотреть предложение, измените магазин
      </VText>
      <VText tag="div" font="body-large-regular">
        Предложение действует в одном магазине:
      </VText>
    </div>
    <div class="app-location-store__inner">
      <component :is="tooltipIconComponent" class="app-location-store__inner--icon" />
      <div class="app-location-store__inner--info">
        <VText class="app-location__info--city" font="body-large-accent-low">
          {{ storeTypeLabel }}
        </VText>
        <VText class="app-location__info--address" font="body-small-regular-low" color="secondary">
          {{ diffStoreAddress }}
        </VText>
      </div>
    </div>
    <div class="app-location-store__inner--actions">
      <VButton full-width @click="handleChangeStore">
        Изменить
      </VButton>
      <VButton full-width theme="secondary" @click="handleCloseStoreChange">
        Нет, закрыть
      </VButton>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.app-location-store {
  &__text {
    display: flex;
    flex-direction: column;
    row-gap: var(--pl-unit-x2);
  }

  &__inner {
    display: flex;
    gap: var(--pl-unit-x3);
    align-items: start;
    width: 100%;
    margin: var(--pl-unit-x4) 0 var(--pl-unit-x8) 0;
    border: 1px solid var(--pl-border-secondary-disabled);
    border-radius: var(--pl-button-radius-l);
    padding: var(--pl-unit-x3) var(--pl-unit-x4);
    background-color: var(--pl-button-background-tertiary-default);

    &--icon {
      width: 16px;
      height: 20px;
    }

    &--info {
      display: flex;
      flex-direction: column;
      row-gap: var(--pl-unit-x1);
      max-width: 336px;
    }

    &--actions {
      display: flex;
      gap: var(--pl-unit-x4);
    }
  }
}
</style>
