<script setup lang="ts">
import VCatalogListing from "@magnit/unit-catalog/src/components/VCatalogListing.vue";

const mockSkeletonProduct = new Array(12);
</script>

<template>
  <VWrapperLocal>
    <VCatalogListing loading :items="mockSkeletonProduct" :item-size="{ xs: 6, m: 4, ml: 3 }" />
  </VWrapperLocal>
</template>
