<script setup lang="ts">
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import { provideKeys } from "@magnit/unit-catalog/src/unit-catalog.vars";
import { LocalProvidersKeys } from "~/enums/localProvidersKeys";

interface IHeaderSearchResultsItemProps {
  product: IProduct;
}

const isDialogOpened = inject<Ref<boolean>>(provideKeys.isDialogOpened, ref(false));
const isAdultApproved = inject<Ref<boolean>>(provideKeys.approved, ref(false));
const isAgeConfirmationActive = inject<Ref<boolean>>(
  LocalProvidersKeys.ageConfirmationActive,
  ref(false),
);

const props = defineProps<IHeaderSearchResultsItemProps>();

const waitingRoute = ref<(() => void) | null>(null);
const checkAge = computed(() => !isAdultApproved.value && props.product.adult);
const handleAdultClick = (event: Event, navigate: () => void) => {
  event.preventDefault();
  event.stopPropagation();
  isAgeConfirmationActive.value = true;
  isDialogOpened.value = true;
  waitingRoute.value = navigate;
};
const stopIfAdult = (e: Event) => {
  if (checkAge.value) {
    e.stopPropagation();
  }
};
watch(
  () => isDialogOpened.value,
  (next, prev) => {
    if (prev && !next && waitingRoute.value) {
      if (isAdultApproved.value) {
        waitingRoute.value();
      }
      nextTick(() => {
        waitingRoute.value = null;
        isAgeConfirmationActive.value = false;
      });
    }
  },
);
onBeforeUnmount(() => {
  waitingRoute.value = null;
});
</script>

<template>
  <NuxtLink v-slot="{ href, navigate }" :to="product.link" custom @click="stopIfAdult">
    <a
      v-if="checkAge"
      class="header-search-results-item header-search-results-item__check"
      @click.stop="handleAdultClick($event, navigate)"
    >
      <AppSearchSuggestResultsItemContent :product="product" blured />
    </a>
    <a v-else class="header-search-results-item" :href="href" @click="navigate">
      <AppSearchSuggestResultsItemContent :product="product" />
    </a>
  </NuxtLink>
</template>

<style lang="postcss" scoped>
.header-search-results-item {
  display: flex;
  align-items: flex-start;
  padding: var(--pl-unit-x3) var(--pl-unit-x2);
  text-decoration: none;
  border-radius: var(--pl-unit-x3);
  background-color: var(--pl-surface-primary-default);

  &:hover {
    background-color: var(--pl-surface-primary-hover);
  }

  &:active {
    background-color: var(--pl-surface-primary-pressed);
  }

  &__check {
    cursor: pointer;
  }
}
</style>
