<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  transitionName: {
    type: String,
    default: TRANSITION_SLIDE_DOWN,
  },
  lifespan: {
    type: Number,
    default: 3000,
  },
  active: {
    type: Boolean,
    default: true,
  },
  pseudo: Boolean,
});

const emit = defineEmits(["close:toast"]);
const timeoutToken = ref<Nullable<NodeJS.Timeout>>(null);

const iconComponent = computed(() => {
  if (props.icon === ICON_TOAST_SUCCESS) return resolveComponent("LazyIconToastSuccess");
  if (props.icon === ICON_TOAST_ERROR) return resolveComponent("LazyIconToastError");
  if (props.icon === ICON_TOAST_WARNING) return resolveComponent("LazyIconToastWarning");
  return resolveComponent("LazyIconToastWarning");
});

const clear = () => {
  if (timeoutToken.value) clearTimeout(timeoutToken.value);
};
const handleCloseToast = () => {
  emit("close:toast");
  clear();
};

onMounted(() => {
  if (props.pseudo) return;
  timeoutToken.value = setTimeout(handleCloseToast, props.lifespan);
});
onUnmounted(clear);
</script>

<template>
  <transition :name="transitionName" appear>
    <div v-if="active" class="custom-message" @click="handleCloseToast">
      <component :is="iconComponent" v-if="icon" />
      <div class="custom-message__inner">
        <p v-if="title" class="custom-message__title">
          {{ title }}
        </p>
        <p v-if="text" class="custom-message__text">
          {{ text }}
        </p>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.custom-message {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 0;
  background-color: #474747;
  border-radius: 12px;
  width: auto;
  min-width: 320px;
  max-width: 420px;

  & svg {
    margin: 0 13px;
  }

  &__inner:first-child {
    margin-left: 16px;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    color: #fff;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin: 0;
    color: #b8b8b8;
  }
}
</style>
