import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import type { Messaging } from "firebase/messaging";

const useFirebaseInit = () => {
  const messaging = ref<Messaging | null>(null);
  const deviceId = useCookie(storage.uniqDeviceID, {
    readonly: true,
  });

  watch(deviceId, async (newDeviceId) => {
    if (import.meta.client && navigator && navigator.serviceWorker) {
      await navigator.serviceWorker.ready;
      navigator.serviceWorker?.controller?.postMessage({
        type: "MESSAGE_DEVICE_ID",
        deviceId: newDeviceId || "omit empty",
      });
    }
  }, {
    immediate: import.meta.client,
  });

  onMounted(async () => {
    const pushStore = usePushStore();
    const app = initializeApp(pushStore.fbaseConfig);
    const permissionStatus = navigator.permissions
      ? await navigator.permissions.query({
        name: "notifications",
      })
      : undefined;
    if (permissionStatus?.state === "granted") {
      messaging.value = getMessaging(app);
      onMessage(messaging.value, async (payload) => {
        const payloadData = payload?.data;
        if (navigator && navigator.serviceWorker && payload) {
          const registration = await navigator.serviceWorker.ready;
          navigator.serviceWorker.controller?.postMessage({
            type: "MESSAGE_DEVICE_ID",
            deviceId: deviceId.value || "omit empty",
          });
          navigator.serviceWorker.controller?.postMessage({
            ...payloadData,
            type: "MESSAGE_PUSH_RECEIVED",
          });
          if (registration) {
            await registration.showNotification(payload.notification?.title || "", {
              ...payload.notification,
              data: {
                ...payload.data,
                FCM_MSG: { data: { ...payload.data } },
              },
            });
          }
        }
      });
    }
  });
};

export default useFirebaseInit;
