<script setup lang="ts">
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VInstallAppBanner from "@magnit/core/src/containers/VInsallAppBanner/VInstallAppBanner.vue";
import MagnitAppIcon from "@magnit/icons/src/assets/icons/logo-magnit-app-36.svg";
import { useLocalStorage } from "@vueuse/core";
import { getMobileOS } from "~/utils/clientInfo";

const appBannerCloseKey = "app-banner-close";
const isOpenBanner = ref(true);

const onClickBannerInstall = () => {
  window.open(
    "https://redirect.appmetrica.yandex.com/serve/317105918359676932",
    "_blank",
    "noopener noreferrer",
  );
};

const isLocalStorageBanner = useLocalStorage(appBannerCloseKey, "");

const isShowAppBanner = computed(() => {
  if (!isOpenBanner.value || isLocalStorageBanner.value) {
    return false;
  }
  if (window && "navigator" in window) {
    const { browser } = getBrowserInfo();

    return !(browser === "Safari" && ["ios", "other"].includes(getMobileOS()));
  }
  return false;
});

const onBannerClose = () => {
  const banner = useLocalStorage(appBannerCloseKey, "");
  banner.value = "1";
  isOpenBanner.value = false;
};
</script>

<template>
  <VInstallAppBanner
    button-title="Открыть"
    :open="isShowAppBanner"
    @click="onClickBannerInstall"
    @close="onBannerClose"
  >
    <template #icon>
      <VIcon>
        <MagnitAppIcon />
      </VIcon>
    </template>
  </VInstallAppBanner>
</template>
