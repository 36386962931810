<script setup lang="ts">
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import { getObjectHash } from "~/utils/hash";
import type { ICatalogBFFGoodsSearchV2Params } from "~/typings/api/goods";

const SUGGESTS_COUNT = 6;

const props = defineProps<{
  searchValue: string;
  openSuggest: boolean;
}>();

const emit = defineEmits<{
  "update:openSuggest": [value: boolean];
  "update:searchValue": [value: string];
}>();

const { requestProducts } = goodsApi();
const { requestPromotionsSearch } = promoApi();
const { send } = useAnalytics();

const closeSuggests = () => {
  if (!props.openSuggest) {
    return;
  }
  emit("update:openSuggest", false);
};

const items = ref<IProduct[]>([]);
const status = ref<"initial" | "pending" | "success" | "error">("initial");
const clearSuggests = (withError?: boolean) => {
  items.value = [];
  status.value = withError ? "error" : "initial";
};

const closeError = () => {
  closeSuggests();
  emit("update:searchValue", "");
  send("Search:Error:Button:Click", { query: props.searchValue, type_error: "noProduct" });
};

const checkSearchValue = () => {
  if (props.searchValue && props.searchValue.length >= 3) return true;
  clearSuggests();
};
const fetchSuggests = async () => {
  if (!checkSearchValue()) return;

  status.value = "pending";

  try {
    const allSuggests: IProduct[] = [];
    const promoPayload = { query: props.searchValue, limit: SUGGESTS_LIMIT };
    const goodsPayload: ICatalogBFFGoodsSearchV2Params = {
      term: props.searchValue,
      pagination: { offset: 0, limit: SUGGESTS_LIMIT },
      sort: DEFAULT_SORT_BFF_GOOD,
    };
    const [promo, goods] = await Promise.allSettled([
      requestPromotionsSearch(promoPayload, getObjectHash(promoPayload)),
      requestProducts(goodsPayload, getObjectHash(goodsPayload)),
    ]);

    if (promo.status !== "fulfilled" && goods.status !== "fulfilled") {
      clearSuggests(true);
      status.value = "error";
      return;
    }

    if (promo.status === "fulfilled") {
      allSuggests.push(...(promo.value.data.value || []));
    }

    if (goods.status === "fulfilled") {
      allSuggests.push(...(goods.value.data.value?.goods || []));
    }

    items.value = [...allSuggests].slice(0, SUGGESTS_COUNT);
  } catch {
    clearSuggests(true);
    status.value = "error";
  }

  status.value = "success";
};

watchDebounced(
  () => props.searchValue,
  async () => {
    await fetchSuggests();
  },
  { debounce: 300 },
);

const showEmptyGoods = computed(
  () =>
    props.openSuggest &&
    props.searchValue.length >= 3 &&
    ["error", "success"].includes(status.value) &&
    !items.value.length,
);

watch(showEmptyGoods, (v) => {
  if (!v) return;
  send("Search:Error:View", { query: props.searchValue, type_error: "noProduct" });
});
</script>

<template>
  <AppSearchSuggestCatalog
    v-if="status === 'initial'"
    :suggests-open="openSuggest"
    @close="closeSuggests"
  />
  <AppSearchSuggestSkeleton v-if="status === 'pending'" />
  <AppSearchSuggestResults
    v-else-if="status === 'success' && items.length"
    :items="items"
    :query="searchValue"
    @click="closeSuggests"
    @results="closeSuggests"
  />
  <div v-else-if="showEmptyGoods" class="search-suggest-error">
    <AppEmptyGoods @click:primary="closeError" @click:secondary="closeError" />
  </div>
</template>

<style scoped lang="postcss">
.search-suggest-error {
  padding: 0 20px 20px;
}
</style>
